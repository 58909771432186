import '../../scss/home/AboutZodiToken.scss';

import { ReactComponent as LogoZ } from '../../assets/icons/Charis-sim.svg';

const AboutZodiToken: React.FC = () => {
  return (
    <section className="about-zodi-token">
      <div className="about-zodi-token-image">
        <LogoZ />
      </div>
      <div className="about-zodi-token-description">
        <h1>
          What is
          <br />
          CHARIS Token?
        </h1>
        <p>
          Charis utilizes (CHARIS) as a
          <br />
          utility token for its cosmos.
          <br />
          By acquiring Charis through various ways,
          <br />
          token holders can play games
          <br />
          and claim their rewards.
        </p>
        <p>
          Charis intends to support chain-swap
          <br />
          between multi main-nets:
          <br />
          BNB chain (BEP20), Solana, Blast,
          <br />
          Base and Stacks.
          <br />
          <br />
          Total supply : 888,888,888 CHARIS
        </p>
      </div>
    </section>
  );
};

export default AboutZodiToken;
