import AboutPartners from '../containers/Home/AboutPartners';
import AboutSoulZodiac from '../containers/Home/AboutSoulZodiac';
import AboutUniverse from '../containers/Home/AboutUniverse';
import AboutUntouchable from '../containers/Home/AboutUntouchable';
import AboutUpgrade from '../containers/Home/AboutUpgrade';
import AboutZodiToken from '../containers/Home/AboutZodiToken';
import AboutZodium from '../containers/Home/AboutZodium';

import HomeSwiper from '../components/HomeSwiper';
import Paragraph from '../components/Paragraph';

import useCleanUpFlow from '../hooks/useCleanUpFlow';

import '../scss/home/Home.scss';

const Home: React.FC = () => {
  useCleanUpFlow();

  return (
    <>
      {/* <main className="main-image">
                <Paragraph
                    title={`Discover Zodiac Signs\nto Play and Earn!`}
                    description={`Play, Earn and Be Yourself with Soul Zodiac!`}
                    buttonTitle={`Draw Zodiac`}
                    link={`/drawZodiac`}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    whiteTitle
                />
                <div className="image-wrapper">
                    <img src={ZodiacLeft} alt="left" className="left" />
                    <img src={ZodiacRight} alt="right" className="right" />
                </div>
            </main> */}
      <HomeSwiper />
      <AboutZodium />
      <AboutZodiToken />
      <AboutUniverse />
      <AboutSoulZodiac />
      <AboutUntouchable />
      <AboutUpgrade />
      <AboutPartners />
      <section className="about-future">
        <Paragraph
          title={`The Vision`}
          description={`Zodium's ultimate vision is to become a global corporation that tells a unique story\nin multiple business sectors, including NFT, game, animation, and various IP businesses.`}
          buttonTitle={`Find Out More`}
          link={`https://whitepaper.charis-x.com/`}
        />

        <Paragraph
          title={`The Vision`}
          description={`Zodium's ultimate vision is to become a global corporation that tells a unique story\nin multiple business sectors, including NFT, game,\nanimation, and various IP businesses.`}
          buttonTitle={`Find Out More`}
          link={`https://whitepaper.charis-x.com/`}
        />

        <Paragraph
          title={`The Vision`}
          description={`Zodium's ultimate vision is\nto become a global corporation\nthat tells a unique story\nin multiple business sectors,\nincluding NFT, game, animation,\nand various IP businesses.`}
          buttonTitle={`Find Out More`}
          link={`https://whitepaper.charis-x.com/`}
        />
      </section>
    </>
  );
};

export default Home;
