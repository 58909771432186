import { useCallback, useEffect } from 'react';
import { useQueries } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { powerMap } from 'constants/weaponPower';
import { RootState } from 'store';
import { setUserOwnWeaponTokenIDs } from 'store/userWeapon';

import { useWeaponBaseContract } from 'hooks/useContract';
// eslint-disable-next-line
import { useWeaponCollection } from 'hooks/useWeaponCollection';
import { useWeaponCustomize } from 'hooks/useWeaponCustomize';

import extractWeaponAttributes from 'lib/extractWeaponAttributes';

import {
  WeaponAttributes,
  WeaponType,
  weaponElements,
  weaponNames,
} from 'types/weapon';

export type WeaponMetadataResponse = {
  id: number;
  booster_id: number;
  tx_hash: string;
  random_number: string;
  image: string;
  external_url: string;
  description: string;
  name: string;
  attributes: any;
  signature: string;
};

const useWeaponCollectionQuery = (active: boolean = true) => {
  // const { getWeaponTokenURI } = useWeaponCollection();
  const { getWeaponTokenIDs } = useWeaponCustomize();
  const weaponbaseContract = useWeaponBaseContract();
  const tokenIds = useSelector((state: RootState) => state.userWeapon.tokenIds);
  const dispatch = useDispatch();

  const getUri = useCallback(async (tokenId: string) => {
    try {
      // for test
      if (process.env.NODE_ENV !== 'production') {
        const baseURI =
          'https://s3.ap-northeast-2.amazonaws.com/betaweaponnftinfo.zodium.io/';
        // for disable brower cache, add query param
        return `${baseURI}${tokenId}?_=${new Date().getTime()}`;
      }

      // const _uri = await getWeaponTokenURI(tokenIds[tokenIds.length - 1]);
      // const baseURI = `https://${_uri.split('/')[2]}/`;
      const baseURI =
        'https://s3.ap-northeast-2.amazonaws.com/weaponnftinfo.zodium.io/';
      return `${baseURI}${tokenId}?_=${new Date().getTime()}`;
    } catch (err) {
      console.log(err);
      return null;
    }
  }, []);

  const fetchTokenIds = useCallback(async () => {
    const tokenIds = await getWeaponTokenIDs();
    if (tokenIds) {
      dispatch(setUserOwnWeaponTokenIDs(tokenIds));
    }
  }, [getWeaponTokenIDs, dispatch]);

  useEffect(() => {
    fetchTokenIds();
  }, [fetchTokenIds]);

  const res = useQueries(
    tokenIds.map((tokenId) => ({
      queryKey: ['weapon', tokenId],
      queryFn: async () => {
        const uri = await getUri(tokenId);
        if (!uri) return null;

        const { data } = await axios.get<WeaponMetadataResponse>(uri);
        // console.log('metadata', data);

        /**
         * for test
         * contract에서 조회한 attr 사용 (네트워크 속도 이슈로 사용 안함)
         * tokenID, name, element, exp, level 순
         */
        if (process.env.NODE_ENV !== 'production') {
          const [name, element, exp, level] =
            await weaponbaseContract.getTokenAttributes(tokenId);
          const attributes: WeaponAttributes = {
            rarity: Math.ceil(level.toNumber() / 10),
            name: weaponNames[name.toNumber()],
            element: weaponElements[element.toNumber()],
            stackedExp: exp.toNumber(),
            power: powerMap[level.toNumber()],
            level: level.toNumber(),
          };
          // console.log('attr', attributes);

          const weapon: WeaponType = { ...data, attributes };
          return weapon;
        }

        const attributes: WeaponAttributes = extractWeaponAttributes(
          data.attributes,
        );
        // console.log('attr', attributes);

        const weapon: WeaponType = { ...data, attributes };
        return weapon;
      },
      enabled: active,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
    })),
  );

  const data = res
    .map((r) => {
      if (r.isSuccess) return r.data;
      return null;
    })
    .filter((d) => d !== null) as WeaponType[];
  const isLoading = res.findIndex((r) => r.isLoading) !== -1;
  const isError = res.findIndex((r) => r.isError) !== -1;

  return { data, isLoading, isError, fetchTokenIds };
};

export default useWeaponCollectionQuery;
