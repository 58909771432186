import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useTokenBalanceQuery from 'api/useTokenBalanceQuery';
import { CHARIS as charisAddress } from 'config';

import Button from '../components/Button';
import Dim from '../components/Dim';
import Token from '../components/Token';
import WalletAddress from '../components/WalletAddress';

import useScrollLock from '../hooks/useScrollLock';
import { addTokenToMetamask, useWeb3 } from '../hooks/useWeb3';
import useZodium from '../hooks/useZodium';

import { checkAnimal, checkStarSign } from '../lib/checkBirth';
import { monthArray, monthArrayMobile } from '../lib/date';

import '../scss/MyWallet.scss';

import { ReactComponent as MetamskIcon } from '../assets/icons/metamask-icon.svg';
import { ReactComponent as PlusIcon } from '../assets/icons/plus-btn.svg';
import { ReactComponent as ButtonX } from '../assets/icons/x.svg';
import CHARIS from '../assets/images/CHARIS.png';
import ZODI from '../assets/images/ZODI.png';
import { RootState } from '../store';
import { MyWalletClose } from '../store/connectWallet';
import { r_disconnect } from '../store/connected';

const MyWallet: React.FC = () => {
  useScrollLock();
  const dispatch = useDispatch();
  const { account, active, disconnect } = useWeb3();
  const { getBalanceOf } = useZodium();

  const [totalBalance, setTotalBalance] = useState('0');

  const userBirth = useSelector((state: RootState) => state.connected);
  const monthString = useMemo(
    () =>
      userBirth.month.length === 2
        ? monthArray[parseInt(userBirth.month) - 1]
        : userBirth.month.length === 3
        ? monthArray[monthArrayMobile.indexOf(userBirth.month)]
        : userBirth.month,
    [userBirth],
  );

  const { balance: charisBalance } = useTokenBalanceQuery(
    charisAddress,
    account ?? undefined,
  );

  useEffect(() => {
    if (active) {
      getTokenBalanceOf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const getTokenBalanceOf = useCallback(async () => {
    try {
      let total = await getBalanceOf();
      setTotalBalance(total);
    } catch (error) {
      console.error(error);
    }
  }, [getBalanceOf]);

  const disconnectWallet = useCallback(async () => {
    try {
      await disconnect();
      dispatch(r_disconnect());
      dispatch(MyWalletClose());
    } catch (error) {
      console.error(error);
    }
  }, [disconnect, dispatch]);

  const handleWalletModalClose = useCallback(() => {
    dispatch(MyWalletClose());
  }, [dispatch]);

  return (
    <>
      <section className="my-wallet">
        <h2>My Wallet</h2>
        <WalletAddress account={account} prepend={''} short={true} />
        <div className="my-token">
          <span>My Token</span>
          <div className="add-token-btn" onClick={() => addTokenToMetamask()}>
            <PlusIcon />
            <div>All</div>
            <MetamskIcon />
            <span className="tooltip">Add to Metamask</span>
          </div>
        </div>
        <div className="token-wrapper">
          <div>
            <Token
              name={'ZODI'}
              amount={totalBalance}
              buyUrl={'https://pancakeswap.finance/swap'}
              tokenImg={ZODI}
              style={{ backgroundColor: '#FF36B2' }}
            />
          </div>
          <div>
            <Token
              name={'CHARIS'}
              amount={charisBalance ? charisBalance.toString() : 0}
              buyUrl={'https://pancakeswap.finance/swap'}
              tokenImg={CHARIS}
              style={{ backgroundColor: '#F75CBC' }}
            />
          </div>
        </div>

        <div className="birth-wrapper">
          <h3>Date of birth</h3>
          <p>{`${monthString}/${userBirth.day}/${userBirth.year}`}</p>
        </div>
        <div className="sign-wrapper">
          <div>
            <h3>Zodiac Sign</h3>
            <p>{checkAnimal(userBirth.year)}</p>
          </div>
          <div>
            <h3>Star Sign</h3>
            <p>{checkStarSign(userBirth.month, userBirth.day)}</p>
          </div>
        </div>
        <p>
          Find out your zodiac sings and <br /> acquire Soul Zodiac!
        </p>
        <div className="buttons">
          <div>
            <Button
              title="View on bscscan"
              width="100%"
              onClick={() =>
                window.open(`https://testnet.bscscan.com/address/${account}`)
              }
            />
          </div>
          <div>
            <Button
              title="Close"
              buttonStyle="orange-close"
              width="100%"
              onClick={handleWalletModalClose}
            />
          </div>
          <button onClick={disconnectWallet}>Disconnect Wallet</button>
        </div>
        <ButtonX onClick={handleWalletModalClose} />
      </section>
      <Dim zIndex={1200} dimClose={handleWalletModalClose} />
    </>
  );
};

export default MyWallet;
