import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Button from '../components/Button';
import Dim from '../components/Dim';

import { useWeb3 } from '../hooks/useWeb3';

import '../scss/Header.scss';

import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { MyWallet, connectWalletToggle } from '../store/connectWallet';
import { drawedFalse } from '../store/drawed';
import { clearPack } from '../store/newPack';

const Header: React.FC = () => {
  const history = useHistory();
  const { location } = history;

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const [menuClick, setMenuClick] = useState<string>('');

  const dispatch = useDispatch();
  const { active } = useWeb3();

  return (
    <header className="header">
      <Link to="/">
        <Logo />
      </Link>

      <nav className={`${openSidebar ? 'open-sidebar' : ''}`}>
        <div className="main-nav">
          <ul>
            {/* headerUpdate 2205 */}
            <li
              onClick={() => {
                setOpenSidebar(false);
                setMenuClick('');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              className={`${location.pathname === '/' ? 'on' : ''}`}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={`${
                menuClick === 'about' && location.pathname === '/zodiverse'
                  ? 'open on'
                  : menuClick === 'about' && location.pathname !== '/zodiverse'
                  ? 'open'
                  : menuClick !== 'about' && location.pathname === '/zodiverse'
                  ? 'on'
                  : ''
              }`}
              onClick={() => {
                setMenuClick('about');
                if (menuClick !== 'about') {
                  setMenuClick('about');
                } else {
                  setMenuClick('');
                }
              }}
            >
              About
              <ul>
                <li
                  onClick={() => {
                    setOpenSidebar(false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className={`${
                    location.pathname === '/zodiverse' ? 'on' : ''
                  }`}
                >
                  <Link to="/zodiverse">Zodiverse</Link>
                </li>
                <li>
                  <a
                    href="https://whitepaper.charis-x.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Whitepaper
                  </a>
                </li>
              </ul>
            </li>
            <li
              onClick={() => {
                setOpenSidebar(false);
                setMenuClick('');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              className={`${location.pathname === '/v2-transform' ? 'on' : ''}`}
            >
              <Link to="/v2-transform">V2 Transform</Link>
            </li>
            <li
              className={`${
                menuClick === 'draw' &&
                (location.pathname === '/drawZodiac' ||
                  location.pathname === '/drawWeapon')
                  ? 'open on'
                  : menuClick !== 'draw' &&
                    (location.pathname === '/drawZodiac' ||
                      location.pathname === '/drawWeapon')
                  ? 'on'
                  : menuClick === 'draw' && location.pathname !== '/drawWeapon'
                  ? 'open'
                  : menuClick === 'draw' && location.pathname !== '/drawZodiac'
                  ? 'open'
                  : ''
              }`}
              onClick={() => {
                if (menuClick !== 'draw') {
                  setMenuClick('draw');
                } else {
                  setMenuClick('');
                }
              }}
            >
              Draw NFTs
              <ul>
                <li
                  onClick={() => {
                    setOpenSidebar(false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    dispatch(clearPack());
                    dispatch(drawedFalse());
                  }}
                  className={`${
                    location.pathname === '/drawZodiac' ? 'on' : ''
                  }`}
                >
                  <Link to="/drawZodiac">KINDs NFT</Link>
                </li>
                <li
                  onClick={() => {
                    setOpenSidebar(false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    dispatch(clearPack());
                    dispatch(drawedFalse());
                  }}
                  className={`${
                    location.pathname === '/drawWeapon' ? 'on' : ''
                  }`}
                >
                  <Link to="/drawWeapon">Weapons NFT</Link>
                </li>
              </ul>
            </li>
            <li
              className={`${menuClick === 'market' ? 'open' : ''}`}
              onClick={() => {
                setMenuClick('market');
                if (menuClick !== 'market') {
                  setMenuClick('market');
                } else {
                  setMenuClick('');
                }
              }}
            >
              Marketplace
              <ul>
                <li>
                  <a
                    href="https://tofunft.com/collection/zodium-12kinds-v2/items"
                    target="_blank"
                    rel="noreferrer"
                  >
                    12KINDs
                  </a>
                </li>
                <li>
                  <a
                    href="https://tofunft.com/collection/zodium-12weapons/items"
                    target="_blank"
                    rel="noreferrer"
                  >
                    12Weapons
                  </a>
                </li>
              </ul>
            </li>
            <li
              onClick={() => {
                setOpenSidebar(false);
                setMenuClick('');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              className={`${
                location.pathname === '/myCollection' ||
                location.pathname === '/upgrade'
                  ? 'on'
                  : ''
              }`}
            >
              <Link to="/myCollection">My Collection</Link>
            </li>
          </ul>
        </div>

        <div className="sub-nav">
          <ul>
            <li
              onClick={() => {
                setOpenSidebar(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              className={`${
                location.pathname === '/findZodi'
                  ? 'find-zodi-button on'
                  : 'find-zodi-button'
              }`}
            >
              <Link to="/findZodi">
                <div className="guide">
                  <p>?</p>
                  <div className="guide_popup">
                    <p>
                      Discover your zodiac signs,
                      <br />
                      and buy zodiac pack!
                    </p>
                  </div>
                </div>
                <p className="web">
                  What’s
                  <br />
                  my zodiac
                </p>
                <p className="mobile">What’s my zodiac</p>
              </Link>
            </li>

            <li>
              {active ? (
                <Button
                  title="My Wallet"
                  width="172px"
                  height="52px"
                  onClick={() => {
                    dispatch(MyWallet());
                  }}
                />
              ) : (
                <Button
                  title="Connect Wallet"
                  width="172px"
                  height="52px"
                  onClick={() => {
                    dispatch(connectWalletToggle());
                  }}
                />
              )}
            </li>

            {/* <li
              className="zodium-game-button"
              onClick={() => {
                window.open('https://battle.zodium.io/');
              }}
            ></li> */}
          </ul>
        </div>

        {/* {active ? (
          <Button
            title="My Wallet"
            width="300px"
            height="60px"
            buttonStyle="orange-non-radius button-mobile"
            onClick={() => {
              dispatch(MyWallet());
              setOpenSidebar(false);
            }}
          />
        ) : (
          <Button
            title="Connect Wallet"
            width="300px"
            height="60px"
            buttonStyle="orange-non-radius button-mobile"
            onClick={() => {
              dispatch(connectWalletOpen());
              setOpenSidebar(false);
            }}
          />
        )} */}
      </nav>

      <label
        className={`${openSidebar ? 'open-sidebar' : ''}`}
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        <span />
        <span />
        <span />
      </label>

      {openSidebar && (
        <Dim zIndex={1200} dimClose={() => setOpenSidebar(false)} />
      )}
    </header>
  );
};

export default Header;
